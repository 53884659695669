<template>
<div>
  <b-container fluid>
    <b-row class="mb-4" v-if="!permissions.workflow">
      <b-col>
        Access only for RRIC Admins
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.workflow">
      <b-col>
        fff
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'

export default {
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permissions.workflow = ac.can(this.user.acgroups).readAny('workflow').granted
    if (!this.permissions.workflow) {
      this.$router.push({ name: 'Start' })
    }
  },
  data () {
    return {
      permissions: {}
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
